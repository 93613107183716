export default {
  data: {
    address: {},
    locations: [],
    selectedStore: {},
    coords: {},
    redirect: {},
    isHomePage: {},
    urlKeyError: {},
  },
};
