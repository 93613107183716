import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDisclaimer } from '../redux/actions/actions';
import Interweave from 'interweave';
export const InfoWindow = props => {
  const dispatch = useDispatch();
  const [selectedPosition, setselectedPosition] = useState();
  const { selectedStore = {} } = useSelector(state => state.data);
  const [selectedImage, setselectedImage] = useState();
  const [description, setdescription] = useState('');

  // console.log(description);

  const { positionIndex = 0, positions } = selectedStore;
  const [index, setIndex] = useState(0);

  console.log(positions[positionIndex].description);
  useEffect(() => {
    console.log(selectedStore,"sel");
    setselectedPosition(selectedStore.positions[0].code);
    setselectedImage(selectedStore.code);
    setIndex(positionIndex);
    setdescription(positions[index].description);
    dispatch(
      addDisclaimer({
        storeUrl: selectedStore.storeUrl,
        storeCode: selectedStore.code,
        positionCode: positions[positionIndex].code,
        company_disclaimer: selectedStore.company_disclaimer?selectedStore.company_disclaimer:null
      }),
    );
  }, [selectedStore]);

  return (
    <div className="show-content" id="aside">
      <div
        className="bg-cover bg-center h-40 p-4"
        style={{
          backgroundImage: `url(./store-images/${selectedImage}.jpg)`,
        }}
      >
        <div className="flex justify-end">
          <button className="close-content">
            <i
              className="ri-close-line"
              onClick={() => {
                props.toggleInformationView(false);
              }}
            ></i>
          </button>
        </div>
      </div>
      <div className="show-content__inner">
        <div className="flex justify-between items-center">
          <h2>
            {' '}
            {selectedStore.company}
          </h2>
          <button
            onClick={() => {
              window.open(
                'https://www.google.com/maps/dir/' + selectedStore.address,
              );
            }}
            className="directions px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-500 rounded-full mb-0"
          >
            MAP
          </button>
        </div>
        <div className="address font-bold">{selectedStore.code + ''} - {'' + selectedStore.name}</div>
        <div className="address mb-2">{selectedStore.address}</div>
        
        <div className="mb-4 body-heading">
        
          <button
              className="w-full text-sm rounded-full mb-3 bg-blue-700 hover:bg-blue-500"
              onClick={() => {
                props.toggleDisclaimer(true);
              }}
            >
            Apply Now
          </button>

          <div>
            {selectedStore.positions.map((p, ind) => (
              <span
                key={ind}
                className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full bg-gray-200 mb-1 ${
                  ind === index
                    ? 'text-white bg-blue-500'
                    : 'bg-gray-200 text-gray-600'
                } uppercase last:mr-0 mr-1 cursor-pointer`}
                onClick={() => {
                  setselectedPosition(p.code);
                  setselectedImage(selectedStore.code);
                  setdescription(p.description);
                  setIndex(ind);
                  dispatch(
                    addDisclaimer({
                      storeUrl: selectedStore.storeUrl,
                      storeCode: selectedStore.code,
                      positionCode: p.code,
                      company_disclaimer: selectedStore.company_disclaimer?selectedStore.company_disclaimer:null
                    }),
                  );
                }}
              >
                {p.title}
              </span>
            ))}
          </div>
        </div>
        <Interweave content={`${description}`} />
      </div>
    </div>
  );
};
