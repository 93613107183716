import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import logger from 'redux-logger';

export default function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(logger)),
	);
}
