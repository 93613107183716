import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MainPage from './pages/MainPage';
// import NotFoundPage from './pages/NotFoundPage';

// const PUBLIC_URL = 'https://career.workportal.co';

// const getBasename = () => {
//   return `/${PUBLIC_URL.split('/').pop()}`;
// };

function App() {
  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={'/'} component={MainPage} />
        <Route exact path="/:id" component={MainPage} />
      </Switch>
    </Router>
  );
}

export default App;
