import * as types from './actionTypes';
export function addLocationId(data) {
  return { type: types.ADD_LOCATIONS, data };
}

export function getAllLocations(data) {
  return { type: types.LOAD_ALL_LOCATIONS, data };
}

export function addStore(data) {
  return { type: types.ADD_STORE, data };
}

export function addCoordinates(data) {
  return { type: types.ADD_COORDINATES, data };
}

export function addDisclaimer(data) {
  return { type: types.ADD_DISCLAIMER, data };
}

export function getHomePage(data) {
  return { type: types.GET_HOME_PAGE, data };
}

export function getURLKeyError(data) {
  return { type: types.GET_URL_KEY_ERROR, data };
}
