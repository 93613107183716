import React from 'react';
import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';

export const Disclaimer = props => {
  const history = createBrowserHistory();
  const {
    storeCode = '',
    positionCode = '',
    storeUrl = '',
    company_disclaimer = '',
  } = useSelector(state => state.data.redirect);
  let pathName = history.location.pathname;
  console.log(
    `${storeUrl}/application?store=${storeCode}&position=${positionCode}&k=${pathName.slice(
      1,
    )}`,
  );

  console.log(company_disclaimer, 'discl');

  const handleClick = () => {
    window.location.assign(
      `${storeUrl}/application?store=${storeCode}&position=${positionCode}&k=${pathName.slice(
        1,
      )}`,
    );
  };

  return (
    <div className="flex h-screen">
      <div
        className="overflow-auto absolute inset-0 z-50 flex items-start justify-center"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        x-show="showModal"
      >
        <div
          className="bg-white shadow-2xl m-4 sm:m-8 max-w-screen-lg rounded-2xl"
          x-show="showModal"
        >
          <div className="flex justify-between items-center border-b px-8 py-5 text-2xl">
            <h1 className="text-2xl font-bold">
              {company_disclaimer !== null && company_disclaimer !== ''
                ? 'Disclaimer'
                : 'Continue'}
            </h1>
            {/* <button type="button">✖</button> */}
          </div>
          <div className="p-8">
            {company_disclaimer !== null && company_disclaimer !== '' ? (
              <div dangerouslySetInnerHTML={{ __html: company_disclaimer }} />
            ) : null}
            {/* Add Disclaimer Body Here */}

            <hr />
            <div className="flex justify-between mt-4">
              <button
                className="border rounded-full font-bold py-3 px-6 border-blue-800 text-blue-800"
                onClick={() => {
                  props.toggleDisclaimer(false);
                }}
              >
                Cancel
              </button>
              <button
                className="mr-2 font-bold py-3 px-6 bg-gradient-to-r from-blue-800 to-blue-900 rounded-full text-white"
                onClick={handleClick}
              >
                Accept &amp; Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
