import React from 'react';
import {
  addLocationId,
  addStore,
  addDisclaimer,
} from '../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Loader from './Loader';
const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    marginLeft: 30,
    marginTop: 40,
  },
  alert: {
    // width: 400,
    // marginLeft: 30,
    // marginTop: 40,
  },
}));
export const SideList = props => {
  const classes = useStyles();
  const { id: hoverId = null } = useSelector(state => state.data.address);
  const { data = [], msg = '', error = false } = useSelector(
    state => state.data.locations,
  );
  const dispatch = useDispatch();
  console.log(msg);
  return (
    <ul className="search-list mt-0 mb-0">
      <div className={classes.alert}>
        <Alert severity="info">{msg}</Alert>
      </div>
      {data.length === 0 ? (
        <Loader />
      ) : error === true ? null : (
        data.map((store, i) => (
          <li
            key={i}
            className={
              store.id === hoverId
                ? 'transition duration-500 ease-in-out bg-gray-50 bg-gray-200'
                : 'transition duration-500 ease-in-out bg-gray-50 hover:bg-gray-300'
            }
            onMouseOver={() => {
              store.id === hoverId
                ? console.log('clicked')
                : dispatch(addLocationId({ id: store.id }));
            }}
            onMouseLeave={() => {
              //dispatch(addLocationId({ id: null }));
            }}
          >
            <div className="content ml-5">
              <div className="flex items-center justify-between">
                <h2 className="text-xl">
                  {store.code} - {store.name}
                </h2>
              </div>
              <div className="address font-bold">{store.company}</div>
              <div className="flex justify-between">
                <div className="address mb-2">{store.location.address}</div>
                <button
                  className="directions px-2 py-1 text-xs font-bold leading-none text-white bg-yellow-500 rounded-full mb-0"
                  onClick={() => {
                    window.open(
                      'https://www.google.com/maps/dir/' + store.location.address,
                    );
                  }}
                >
                  {/* <i className="ri-direction-line"></i> */}
                  MAP
                </button>
              </div>
              
              <div className="vacancies justify-start">
                {store.positions.map((p, ind) => (                 

                  <span key={ind} className="justify-between mr-1">
                    <button type="button" 
                        className="bg-gray-300 text-xs text-gray-600 font-semibold pl-2 pr-0 py-1 rounded-full leading-none uppercase mb-1" 
                        onClick={() => {
                          dispatch(
                            addStore({
                              id: store.id,
                              name: store.name,
                              code: store.code,
                              company: store.company,
                              address: store.location.address,
                              positions: store.positions,
                              positionIndex: ind,
                              storeUrl: store.url,
                              company_disclaimer:store.company_disclaimer
                            }),
                          );
                          dispatch(
                            addDisclaimer({
                              storeUrl: store.url,
                              storeCode: store.code,
                              positionCode: p.code,
                              company_disclaimer:store.company_disclaimer
                            }),
                          );
                          props.toggleInformationView(true);
                        }}
                      >{p.title} 
                      <span className="bg-gray-500 px-2 py-1 rounded-full text-white text-xs ml-2">{p.count}</span>
                    </button>
                  </span>
                ))}
              </div>
              <button
                className="button"
                onMouseUp={() => {}}
                onClick={() => {
                  dispatch(
                    addStore({
                      id: store.id,
                      name: store.name,
                      code: store.code,
                      company: store.company,
                      address: store.location.address,
                      positions: store.positions,
                      storeUrl: store.url,
                      company_disclaimer:store.company_disclaimer
                    }),
                  );

                  props.toggleInformationView(true);
                }}
              >
                More Info
              </button>
            </div>
          </li>
        ))
      )}
    </ul>
  );
};
