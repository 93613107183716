import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function addLocationsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_LOCATIONS:
      return {
        ...state,
        address: action.data,
      };

    case types.LOAD_ALL_LOCATIONS:
      return {
        ...state,
        locations: action.data,
      };

    case types.ADD_STORE:
      return {
        ...state,
        selectedStore: action.data,
      };

    case types.ADD_COORDINATES:
      return {
        ...state,
        coords: action.data,
      };

    case types.ADD_DISCLAIMER:
      return {
        ...state,
        redirect: action.data,
      };

    case types.GET_HOME_PAGE:
      return {
        ...state,
        isHomePage: action.data,
      };

    case types.GET_URL_KEY_ERROR:
      return {
        ...state,
        urlKeyError: action.data,
      };
    default:
      return state;
  }
}
