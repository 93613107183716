import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider as ReduxProvider } from 'react-redux';
import initialState from './redux/reducers/initialState';
import configureStore from './redux/configureStore';
import './assets/css/main.css';

const store = configureStore(initialState);
ReactDOM.render(
  <ReduxProvider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReduxProvider>,

  document.getElementById('root'),
);
