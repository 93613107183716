import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import {
  getAllLocations,
  addCoordinates,
  getHomePage,
} from '../redux/actions/actions';
import { createBrowserHistory } from 'history';

export const Header = () => {
  const dispatch = useDispatch();
  const defaultLat = 40.7127753;
  const defaultLon = -174.0059728;
  const [selectedCoors, setselectedCoors] = useState({
    selectedLat: defaultLat,
    selectedLon: defaultLon,
  });
  const [LocationStatus, setLocationStatus] = useState(false);
  const history = createBrowserHistory();
  const { latitude, longitude } = useSelector(state => state.data.coords);
  const [address, setAddress] = useState('');
  const [selectedRadious, setSelectedRadious] = useState(10);
  const userStatus = require('../assets/json/user-status.json');
  const [searchStatus, setSearchStatus] = useState(false);
  const [company, setCompany] = useState([]);
  const [companyinfo, setCompanyinfo] = useState({});

  const api_url = process.env.REACT_APP_API_URL;
  let pathName = history.location.pathname;
  // console.log(latitude, longitude, "------ok");
  //   GLOBAL - Joy7OFfRHSU3xeCp8v_Z1TgNGEIdMi
  // HARI - Iy0J2Qh4A3MSvtHaXZULTu15rDimkc
  // MAW - NAZpoug8U5clHq64xsDLyKTJXItakw
  // KPVLC - f9223574a4eca2686b6f337_ed3713f4c
  const URL = api_url + '/api/v1/recruit-stores';

  useEffect(() => {
    getLocation();
    let selectedComany = userStatus.filter(item => item.key === pathName);
    setCompany(selectedComany);
    loadCompanyInfo();
    // console.log( 'use effect ' ,api_url );

  }, [LocationStatus, pathName]);


  const search = async () => {
    try {
      if (pathName === '/') {
        const response = await axios.get(URL).then(result => {
          dispatch(getAllLocations(result.data));
        });
      } else {
        if (searchStatus === true) {
          addressToCoords(address);

          const response = await axios
            .get(URL, {
              params: {
                key: pathName.slice(1),
                lat: latitude,
                lng: longitude,
                radius: selectedRadious,
              },
            })
            .then(result => {
              dispatch(getAllLocations(result.data));
            });
        } else {
          const response = await axios
            .get(URL, {
              params: {
                key: pathName.slice(1),
              },
            })
            .then(result => {
              dispatch(getAllLocations(result.data));
            });
        }
      }
    } catch (error) {}
  };

  const addressToCoords = address => {
    try {
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          var latitude = results[0].geometry.location.lat();
          var longitude = results[0].geometry.location.lng();
          setselectedCoors({
            selectedLat: defaultLat,
            selectedLon: defaultLon,
          });
          dispatch(
            addCoordinates({
              latitude: latitude,
              longitude: longitude,
              redious: selectedRadious,
            }),
          );
        }
      });
    } catch (error) {}
  };

  const callApi = (p) => {

    if (p.lng === undefined) {
      delete p.lng;
      delete p.lat;
    }
    axios.get(URL,{params:p}).then(result => {dispatch(getAllLocations(result.data));});
  }

  const clickButton = () => {
    try {
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          var latitude = results[0].geometry.location.lat();
          var longitude = results[0].geometry.location.lng();
          /*setselectedCoors({
            selectedLat: defaultLat,
            selectedLon: defaultLon,
          });
          dispatch(
            addCoordinates({
              latitude: latitude,
              longitude: longitude,
              redious: selectedRadious,
            }),
          );*/

          if (pathName === '/') {
            callApi({
              lat: latitude,
              lng: longitude,
              radius: selectedRadious,
            });
          } else {
            callApi({
              key: pathName.slice(1),
              lat: latitude,
              lng: longitude,
              radius: selectedRadious,
            });
          }
        }
      });
    } catch (error) {}
  }
  //console.log(errorFired);
  const getReverseGeocodingData = (lat, lng) => {
    try {
      let latlng = new window.google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          console.log(status);
        }

        if (status === window.google.maps.GeocoderStatus.OK) {
          let address = results[0].formatted_address;

          setAddress(address);
        }
      });
    } catch (error) {}
  };
  const dispatchAddCoordinates = (lat, lon) => {
    dispatch(
      addCoordinates({
        latitude: lat,
        longitude: lon,
        redious: selectedRadious,
      }),
    );
  };
  const getLocation = () => {
    console.log('getLocation was called');

    if (navigator.geolocation) {
      //to load the name
      dispatch(
        getHomePage({
          isZoom: true,
        }),
      );
      navigator.geolocation.getCurrentPosition(showPosition, positionError);
    } else {
      dispatch(
        getHomePage({
          isZoom: true,
        }),
      );
      setLocationStatus(false);

      console.log('Geolocation is not supported by this device');
      dispatchAddCoordinates(defaultLat, defaultLon);
    }
  };

  const positionError = () => {
    console.log('Geolocation is not enabled.');

    //edited
    search();
    dispatch(
      getHomePage({
        isZoom: true,
      }),
    );
  };

  const showPosition = position => {
    dispatchAddCoordinates(position.coords.latitude, position.coords.longitude);

    getReverseGeocodingData(
      position.coords.latitude,
      position.coords.longitude,
    );
    //edited
   
    if (pathName === '/') {
      callApi({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        radius: selectedRadious,
      });
    } else {
      callApi({
        key: pathName.slice(1),
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        radius: selectedRadious,
      });
    }

    console.log('posiiton accepted');
  };

  const handleRadiousChanage = event => {
    setSelectedRadious(event.target.value);
  };

  const loadCompanyInfo = () => {
    let path = pathName;
    path = path === "/" ? path.slice(1) : path;
    let url =  api_url +  '/api/v2/workspace' + path;
    axios.get(url).then(result => {
      setCompanyinfo(result.data.data);    
    }).catch( error => {
      console.log(error);
    });
  }

  

  const handleSearch = () => {
    search();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      search();
    }
  };
  return (
    <header>
      <div className="block px-4 mx-auto">
        <div className="flex-none md:flex items-center">
          <a href="/" className="logo flex items-center mr-7">
            <img
              src={
                companyinfo !== undefined ? companyinfo.logo_url : null
              }
              alt="WorkPortal"
            />
          </a>
          <div className="search-bar col-span-2">
            <div className="search-bar__filter">
              <select value={selectedRadious} onChange={handleRadiousChanage}>
                <option value="3">Closest</option>
                <option value="5">5 Miles</option>
                <option value="10">10 Miles</option>
                <option value="15">15 Miles</option>
                <option value="20">20 Miles</option>
                <option value="25">25 Miles</option>
                <option defaultValue="75" value="75">
                  75 Miles
                </option>
              </select>
            </div>
            {/* <div className="search-bar__filter">
              <select value="crew-member" onChange={() => false}>
                <option value="">Vacancy Type</option>
                <option value="crew-member">Crew Member</option>
                <option value="">Store Manager</option>
                <option value="">Supervisor</option>
                <option value="">Cashier</option>
              </select>
            </div> */}
            <div className="search">
              <input
                type="text"
                name="search"
                id=""
                placeholder="Type in Keyword (Zip, City, State)"
                onChange={e => {
                  //setSearchStatus(true);
                  setAddress(e.target.value);
                }}
                //oninput={addressToCoords(address)}
                value={address}
                onKeyPress={handleKeyPress}
              />
              <button
                className="bg-blue-400 hover:bg-red-600"
                style={(address =="")? {backgroundColor: 'gray'} :{}}
                onClick={e => {
                  address === ''
                    ? console.log('search disable')
                    : clickButton();
                    ///handleSearch();
                  //   address === ''
                  //     ? console.log('add ress not added')
                  //     :
                  //   address === ''
                  //     ? dispatch(
                  //         getHomePage({
                  //           isZoom: true,
                  //         }),
                  //       )
                  //     : dispatch(
                  //         getHomePage({
                  //           isZoom: true,
                  //         }),
                  //       );
                  //   if (
                  //     searchStatus === true &&
                  //     pathName !== '/' &&
                  //     address !== ''
                  //   ) {
                  //     console.log(selectedRadious, latitude, longitude);
                  //     search(selectedRadious, latitude, longitude);
                  //   } else {
                  //     address === '' ? console.log('address is empty') :
                  //   }

                  //   // address === ''
                  //   //   ? setSearchStatus(false)
                  //   //   : setSearchStatus(true);
                  // }}
                }}
              >
                <i className="ri-search-2-line "></i>
              </button>
            </div>
            <div className="support flex items-center justify-end"></div>
          </div>
        </div>
      </div>
    </header>
  );
};
