import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';

const defaulLoader = require('../assets/json/default.json');
const useStyles = makeStyles(theme => ({
  root: {
    // width: 300,
    // marginLeft: 30,
    // marginTop: 40,
  },
  alert: {
    // width: 400,
    // marginLeft: 30,
    // marginTop: 40,
  },
}));
export default function Loader() {
  const classes = useStyles();

  return (
    <div className="p-8 pb-0">
      {defaulLoader.map(item => (
        <div key={item.code} className="mb-5">
          <Skeleton height={20} />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </div>
      ))}
    </div>
  );
}
