import React, { useRef, useState, useEffect } from 'react';
//import useSwr from 'swr'
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';

import { Disclaimer } from '../components/Disclaimer';
import { Header } from '../components/Header';
import { InfoWindow } from '../components/InfoWindow';
import { SideList } from '../components/SideList';
//const fetcher = (...args) => fetch(...args).then(response => response.json());
import { addLocationId, addStore } from '../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';

const Marker = ({ children }) => children;



function MainPage() {
  const dispatch = useDispatch();
  const {
    urlKeyError: { urlKey = true },
    address = {},
    locations: data = {},

    coords: { latitude = 40.7127753, longitude = -74.0059728, redious = 15 },
    isHomePage: { isZoom = true },
  } = useSelector(state => state.data);
  const { id: selecedId = null } = address;

  const mapRef = useRef();
  const mapsRef = useRef();
  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState(null);
  const [loader, setLoader] = useState(true);
  const [count, setCount] = useState(0);

  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };
  const [showInfoView, setShowInfoView] = useState(false);
  const error = false;
  const _onChildClick = childProps => {
    setShowInfoView(!showInfoView);
  };

  const toggleInformationView = status => {
    setShowInfoView(status);
  };

  const getMapBounds = (map, maps, places) => {
    const _bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      _bounds.extend(new maps.LatLng(
        parseFloat(place.location.latitude),
        parseFloat(place.location.longitude),
      ));
    });
    return _bounds;
  };
  
  const bindResizeListener = (map, maps, _bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(_bounds);
      });
    });
  };
  const apiIsLoaded = () => {
    // Get bounds by our places
    const _bounds = getMapBounds(mapRef.current, mapsRef.current, locations);
    // Fit map to bounds
    mapRef.current.fitBounds(_bounds);
    // Bind the resize listener
   // bindResizeListener(mapRef.current, mapsRef.current, bounds);
    // Fit map to bounds
    setBounds(_bounds)
  };
  //const data = require('./assets/json/store-locations.json');
  const locations = data.data && !error ? data.data : [];

  
  if ( locations.length > 0 && (mapRef != undefined || mapRef != null) && count != locations.length ) {
    setCount(locations.length);
    apiIsLoaded(locations);
      //setFistTime(false);
  }
  

  const points = locations.map(store => ({
    type: 'Featurs',
    properties: {
      cluster: false,
      storeId: store.id,
      name: store.name,
      code: store.code,
      address: store.location.address,
      company: store.company,
      positions: store.positions,
      storeUrl: store.url,
      positionIndex: 0,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(store.location.longitude),
        parseFloat(store.location.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: redious,
      maxZoom: 20,
    },
  });

  return (
    <div>
      <Header />

      <div className="grid grid-cols-1 lg:grid-cols-4 relative h-full">
        <div className="col-span-1 relative h-full">
          <div className="relative h-full">
            <aside id="aside">
              <SideList
                locations={locations}
                toggleInformationView={toggleInformationView}
              />
            </aside>
          </div>
        </div>

        <div
          className="col-span-3 relative"
          style={{
            height: 'calc(99.7vh - 82px)',
            width: '100%',
          }}
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GMAP_API_KEY,
            }}
            fitBounds= {apiIsLoaded}
             defaultCenter={{
               lat: 40.7127753,
               lng: 74.0059728,
             }}
            //indianapolis
            
            zoom={isZoom !== true ? 5 : 12}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map,maps }) => {
              mapRef.current = map;
              mapsRef.current = maps;
              apiIsLoaded(locations);
            }}
            
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ]);
            }}
          >
            {clusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount,
              } = cluster.properties;
              if (isCluster) {
                return (
                  <Marker key={cluster.id} lat={latitude} lng={longitude}>
                    <div
                      className="cluster-marker"
                      style={{
                        width: `56px`,
                        height: `55px`,
                        fontSize: `14px`,
                        backgroundImage: `url(./map-clusters/m2.png)`,
                      }}
                      onClick={e => {
                        e.preventDefault();

                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          12,
                        );

                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({
                          lat: latitude,
                          lng: longitude,
                        });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              }

              return (
                <Marker
                  key={index}
                  lat={latitude}
                  lng={longitude}
                  id={cluster.properties.storeId}
                  show={showInfoView}
                >
                  <div
                    className={`map-point ${
                      cluster.properties.storeId === selecedId
                        ? 'transition duration-500 ease-in-out transform -translate-y-1 scale-125'
                        : 'transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-125'
                    } `}
                  >
                    <button
                      onMouseOver={() => {
                        cluster.properties.storeId === selecedId
                          ? console.log('clicked')
                          : dispatch(
                              addLocationId({
                                id: cluster.properties.storeId,
                              }),
                            );
                      }}
                      onMouseLeave={() => {
                        //dispatch(addLocationId({ id: null }));
                      }}
                      className="map-point__point "
                      data-template="one"
                      data-placement="right"
                      onClick={() => {
                        _onChildClick(cluster.properties.storeId);

                        dispatch(
                          addStore({
                            id: cluster.properties.id,
                            name: cluster.properties.name,
                            code: cluster.properties.code,
                            address: cluster.properties.address,
                            company: cluster.properties.company,
                            positions: cluster.properties.positions,

                            positionIndex: cluster.properties.positionIndex,
                            storeUrl: cluster.properties.storeUrl,
                          }),
                        );
                        // dispatch(
                        //   addDisclaimer({
                        //     storeUrl: store.url,
                        //     storeCode: store.code,
                        //     positionCode: p.code,
                        //   }),
                        // );
                        toggleInformationView(true);
                      }}
                    >
                      <img src={'./logos/pin.png'} />
                    </button>
                  </div>
                </Marker>
              );
            })}
          </GoogleMapReact>

          {showInfoView === true ? (
            <InfoWindow
              locations={address}
              toggleInformationView={toggleInformationView}
              toggleDisclaimer={toggleDisclaimer}
            />
          ) : null}
        </div>

        {showDisclaimer === true ? (
          <Disclaimer toggleDisclaimer={toggleDisclaimer} />
        ) : null}
      </div>
    </div>
  );
}

export default MainPage;
